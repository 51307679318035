<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariffs-table-item__title medium-text">
        {{ item.name }}
      </div>
    </template>
    <template #address>
      <div class="tariffs-table-item__ip">
        {{ item.cidr }}
      </div>
      <div class="tariffs-table-item__specs note-text">
        {{ item.description }}
      </div>
    </template>
    <template v-if="isPrivate(item)" #dhcp>
      <div class="tariffs-table-item__ip">
        <tumbler :value="item.enable_dhcp" class="theme-switcher__tumbler" @change="setDHCP" />
      </div>
    </template>
    <template v-if="isPrivate(item)" #dns>
      <div class="tariffs-table-item__ip">
        <!--        <div v-for="name in dnsList" :key="name" class="tariffs-table-item__sub">-->
        <!--          {{ name }}-->
        <!--        </div>-->
        {{ dnsList }}
        <!--        <tumbler :value="item.enable_dhcp" class="theme-switcher__tumbler" @change="setDHCP" />-->
      </div>
    </template>
    <template #pools>
      <div v-for="name in basePools" :key="name" class="tariffs-table-item__sub">
        {{ name }}
      </div>
      <!--      {{ viewPools }}-->
    </template>
    <template #gateway>
      <div class="tariffs-table-item__ip">
        {{ item.gateway_ip }}
      </div>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button
          icon="more"
          class="tariffs-table-item__more"
          @click="togglePopoverState"
        /><template #popover>
          <private-subnet-context-menu
            :tariff="item"
            class="tariff-table-item__context-menu"
            @change-pool="getPools"
        /></template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import Tumbler from '@/components/BaseTumbler/Tumbler.vue';
import PrivateSubnetContextMenu from './PrivateSubnetContextMenu';
import popoverFix from '@/mixins/popoverFix';
export default {
  name: 'TariffsTablePrivateRow',
  components: {
    BaseTableRow,
    Tumbler,
    PrivateSubnetContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      basePools: [],
    };
  },
  computed: {
    subnets() {
      return this.$store.state.moduleStack.subnets;
    },
    dnsList() {
      return this.item.dns_nameservers.join('; ');
    },
  },
  watch: {},
  mounted() {
    this.getPools();
  },
  methods: {
    getPools() {
      const pools = this.item.allocation_pools.sort((x, y) => {
        return +x.start.split('.').at(-1) - +y.start.split('.').at(-1);
      });
      this.basePools = pools.map(x => {
        return `${x.start} - ${x.end}`;
      });
    },
    isPrivate(net) {
      return !this.$store.state.moduleStack.networks.find(x => x.id === net.network_id).shared;
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
    isBootable(item) {
      return item.bootable === 'true' ? 'Загрузочный' : ' - ';
    },
    viewSubnets(id) {
      return this.subnets.find(i => i.id === id).name;
    },
    setDHCP(value) {
      const payload = {
        subnet: {
          enable_dhcp: value,
        },
        id: this.item.id,
        item: 'enable_dhcp',
      };
      this.$store.dispatch('moduleStack/updateSubnet', payload);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: ellipsis;
      //text-overflow: unset;
      //overflow: visible;
      overflow: hidden;
      z-index: 2;
    }
  }
  &__sub{
    margin-top: 0.5rem;
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
